import React, {useContext} from "react";
import { Container, Row, Col } from "react-bootstrap";

import {Section, Title, Text} from "../../components/Core";
import PageWrapper from "../../components/PageWrapper";
import imgS1 from "../../assets/image/jpg/works/5-0.jpg";
import {Button} from "../../components/Core";
import GlobalContext from "../../context/GlobalContext";
import Slider from "react-slick";
import ImageZoom from 'react-image-zooom';

const WorkSingle = () => {
  const gContext = useContext(GlobalContext);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    focusOnSelect: false
  };
  return (
      <>
        <PageWrapper>
          <Container
              css={`
            margin-top: 92px;
            padding-bottom: 0;
          `}>
            <Slider {...settings}>
              <div className={"p-1"}>
                <ImageZoom src={imgS1} className="w-100" css={`
            max-width: 500px
          `}/>
              </div>
            </Slider>
          </Container>
          <Section>
            <Container>
              <Row>
                <Col lg="8">
                  <Title variant="secSm" className="my-4">
                    Яркая, нежная и супер теплая шапочка
                  </Title>
                  <Text
                      variant="p"
                      css={`
                    max-width: 750px;
                  `}
                  >
                    С ней вас точно заметят!
                    <br/>
                    Распушится ещё больше на морозе и в снегопад.
                    <br/>
                    Связать под заказ можно в другом цвете/размере.
                  </Text>
                </Col>
              </Row>
            </Container>
          </Section>
          <div className="mt-lg-3">
            <Container>
              <Row>
                <Col lg="3">
                  <Text variant="tag">Пряжа</Text>
                  <Title variant="cardBig" className="mt-3">
                    Китай
                  </Title>
                </Col>
                <Col lg="5">
                  <Text variant="tag">Состав</Text>
                  <Title variant="cardBig" className="mt-3">
                    Пух норки 80% <br/> ПА 20%
                  </Title>
                </Col>
                <Col lg="4">
                  <Text variant="tag">Цена*</Text>
                  <Title variant="cardBig" className="mt-3">
                    69 BYN
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col lg="7" className={"d-flex align-items-center"}>
                  <Text variant="small">* цена зависит от размера и стоимости пряжи на момент заказа</Text>
                </Col>
                <Col lg="5" className={"mt-2"}>
                  <Button arrowRight onClick={(e) => {
                    e.preventDefault();
                    gContext.toggleOrder();
                  }}>Оставить заявку</Button>
                </Col>
              </Row>
            </Container>
          </div>
        </PageWrapper>
      </>
  );
};
export default WorkSingle;
